<template>
  <div class="box">
    <MatchMedia query="(max-width: 840px)" v-slot="{ matches }">
      <div v-if="matches" class="mobileBox">
        <div class="filter">
          <div class="total">
            Total Products
            <span class="num">
              {{ total }}
            </span>
          </div>

          <div class="type-wrap">
            <!-- <Icon
              type="ios-apps"
              :class="modType === 'card' ? 'cur' : ''"
              @click="modType = 'card'"
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Icon
              type="ios-list"
              :class="modType === 'list' ? 'cur' : ''"
              @click="modType = 'list'"
            /> -->

            <img
              alt=""
              class="icon-sort"
              @click="toSortPop = true"
              src="@/assets/product/icon_sort1.png"
            />
          </div>

          <div class="item">
            <div class="tip"></div>
            <router-link to="/products/create">
              <Button
                :disabled="
                  hackInfomation === 'avatar' || hackInfomation === 'cover'
                    ? true
                    : false
                "
                type="primary"
                icon="md-add"
                >Add Product</Button
              >
            </router-link>
          </div>
        </div>
        <div class="filter">
          <div class="search-box" style="width:100%;margin:0">
            <div class="input-box">
              <input
                v-on:keyup.enter="handleSearch"
                v-model="filter.search"
                type="text"
                placeholder="Search for a product..."
              />
            </div>
            <div class="btn" @click="handleSearch">Search</div>
          </div>
        </div>
      </div>
      <div class="filter" v-else>
        <div class="total">
          <div class="tip">
            Total Products
          </div>
          <div class="num">
            {{ total }}
          </div>
        </div>
        <div class="search-box">
          <div class="input-box">
            <input
              v-on:keyup.enter="handleSearch"
              v-model="filter.search"
              type="text"
              placeholder="Search for a product..."
            />
          </div>
          <div class="btn" @click="handleSearch">Search</div>
        </div>

        <div class="type-wrap">
          <Icon
            type="ios-apps"
            :class="modType === 'card' ? 'cur' : ''"
            @click="modType = 'card'"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Icon
            type="ios-list"
            :class="modType === 'list' ? 'cur' : ''"
            @click="modType = 'list'"
          />

          <img
            class="icon-sort"
            @click="toSortPop = true"
            src="@/assets/product/icon_sort1.png"
          />
        </div>

        <div class="item">
          <div class="tip"></div>
          <router-link to="/products/create">
            <Button
              :disabled="
                hackInfomation === 'avatar' || hackInfomation === 'cover'
                  ? true
                  : false
              "
              type="primary"
              icon="md-add"
              >Add Product</Button
            >
          </router-link>
        </div>
      </div>
    </MatchMedia>

    <div v-if="modType === 'card'" class="cards">
      <Spin v-if="loading" fix></Spin>
      <div
        class="infomation"
        v-if="hackInfomation === 'avatar' || hackInfomation === 'cover'"
      >
        <div class="info-box">
          <Icon class="icon" type="ios-information-circle-outline" />

          <span v-if="hackInfomation === 'avatar'" class="text">
            You must upload a company logo before
            <br />
            you can add/edit products.
          </span>
          <span v-if="hackInfomation === 'cover'" class="text">
            You must upload a cover photo before
            <br />
            you can add/edit products.
          </span>
        </div>
      </div>

      <div class="empty" v-if="list.length === 0">
        No Data
      </div>

      <div v-else>
        <Row type="flex" justify="start" :gutter="16">
          <i-col
            :key="item.id"
            v-for="item in list"
            :span="screenWidth ? 6 : 12"
          >
            <div class="item">
              <router-link
                v-if="item.status !== -3"
                :to="`/products/${item.id}`"
              >
                <div class="img-box">
                  <img alt="" class="avatar" :src="item.images[0].url" />
                  <div v-if="item.status === -3" class="draft">
                    <Icon type="md-repeat" />
                  </div>
                </div>
              </router-link>

              <div v-else class="img-box">
                <img alt="" class="avatar" :src="item.images[0].url" />
                <div v-if="item.status === -3" class="draft">
                  <Icon type="md-repeat" />
                </div>
              </div>

              <div class="name">
                <div class="product-name">{{ item.marketing_name }}</div>
                <div class="factory-name">
                  {{ item.factory && item.factory.factory_name }}
                </div>
              </div>

              <!-- <div class="user">
                <div class="user-avatar">
                  <img class="avatar" :src="item.company.avatar.url" />
                </div>
                <div class="user-name">{{ item.company.company_name }}</div>
              </div> -->

              <div class="tags">
                <div v-if="!item.tags || (item.tags && item.tags.length === 0)">
                  No tags
                </div>

                <div
                  class="tag"
                  :style="{ background: tag.color }"
                  v-for="tag in item.tags"
                  :key="tag.id"
                >
                  {{ tag.name }}
                </div>
              </div>

              <div class="status">
                <span class="key">
                  Status:
                </span>
                <span class="value" v-if="item.status === -3">
                  Incomplete
                </span>
                <span class="value" v-if="item.status === -2">
                  Unpublished
                </span>
                <span
                  @click="
                    $Modal.info({
                      title: 'Rejected reason',
                      content: item.reason
                    })
                  "
                  class="value fail"
                  v-if="item.status === -1"
                >
                  Rejected<Icon
                    class="tip"
                    type="ios-information-circle-outline"
                  />
                </span>
                <span class="value" v-if="item.status === 0">
                  Pending Approval
                </span>
                <span class="value success" v-if="item.status === 1">
                  Approved
                </span>
                <span class="value" v-if="item.status === 2">
                  Published
                </span>
              </div>

              <div class="action">
                <Tooltip content="Edit" placement="top">
                  <Button
                    v-if="item.status === -3"
                    @click="$router.push(`/products/create?draft=${item.id}`)"
                    size="small"
                    icon="md-create"
                    class="mr-10"
                  />
                  <Button
                    v-else
                    @click="$router.push(`/products/${item.id}/edit`)"
                    size="small"
                    icon="md-create"
                    class="mr-10"
                    :disabled="item.redirect_url ? true : false"
                  />
                </Tooltip>

                <Tooltip
                  v-if="item.status === -2 || item.status === 1"
                  content="Publish"
                  placement="top"
                >
                  <Button @click="publish(item.id)" size="small" class="mr-10">
                    <img
                      alt=""
                      class="icon"
                      src="@/assets/product/publish.png"
                    />
                  </Button>
                </Tooltip>

                <Tooltip
                  v-if="item.status === 2"
                  content="Unpublish"
                  placement="top"
                >
                  <Button
                    @click="unpublish(item.id)"
                    size="small"
                    class="mr-10"
                  >
                    <img
                      alt=""
                      class="icon"
                      src="@/assets/product/unpublish.png"
                    />
                  </Button>
                </Tooltip>

                <Tooltip content="Duplicate" placement="top">
                  <Button
                    class="mr-10"
                    @click="copyProduct(item)"
                    size="small"
                    icon="ios-copy-outline"
                  />
                </Tooltip>

                <Tooltip content="Delete" placement="top">
                  <Button
                    @click="removeProduct(item)"
                    size="small"
                    type="error"
                    ghost
                    icon="ios-trash"
                  />
                </Tooltip>
              </div>
            </div>
          </i-col>
        </Row>

        <div class="page">
          <Page
            transfer
            :page-size="limit"
            :page-size-opts="[4, 8, 12, 16]"
            :total="total"
            show-sizer
            show-elevator
            show-total
            @on-change="pageChange"
            @on-page-size-change="pageSizeChange"
          />
        </div>
      </div>
    </div>

    <div class="list" v-else>
      <Table :columns="columns" :data="list" :loading="loading">
        <template slot-scope="{ row }" slot="name">
          <div class="list-head" v-if="row.status === -3">
            <div class="img-box">
              <img alt="" class="avatar" :src="row.images[0].url" />
              <div v-if="row.status === -3" class="draft">
                <Icon type="md-repeat" />
              </div>
            </div>
            <span class="name">
              {{ row.marketing_name }}
            </span>
          </div>
          <router-link v-else :to="`/products/${row.id}`" style="color:#f57c00">
            <div class="list-head">
              <div class="img-box">
                <img alt="" class="avatar" :src="row.images[0].url" />
                <div v-if="row.status === -3" class="draft">
                  <Icon type="md-repeat" />
                </div>
              </div>
              <span class="name">
                {{ row.marketing_name }}
              </span>
            </div>
          </router-link>
        </template>

        <template slot-scope="{ row }" slot="factory">
          <div>{{ row.factory && row.factory.factory_name }}</div>
        </template>

        <template slot-scope="{ row }" slot="type">
          <div>{{ row.product_type }}</div>
        </template>

        <template slot-scope="{ row }" slot="status">
          <div class="status">
            <span class="value" v-if="row.status === -3">
              Incomplete
            </span>
            <span class="value" v-if="row.status === -2">
              Unpublished
            </span>
            <span
              @click="
                $Modal.info({
                  title: 'Rejected reason',
                  content: row.reason
                })
              "
              class="value fail"
              v-if="row.status === -1"
            >
              Rejected<Icon class="tip" type="ios-information-circle-outline" />
            </span>
            <span class="value" v-if="row.status === 0">
              Pending Approval
            </span>
            <span class="value success" v-if="row.status === 1">
              Approved
            </span>
            <span class="value" v-if="row.status === 2">
              Published
            </span>
          </div>
        </template>

        <template slot-scope="{ row: item }" slot="action">
          <div class="action">
            <Tooltip content="Edit" placement="top">
              <Button
                v-if="item.status === -3"
                @click="$router.push(`/products/${item.id}/edit?mode=draft`)"
                size="small"
                icon="md-create"
                class="mr-10"
              />
              <Button
                v-else
                @click="$router.push(`/products/${item.id}/edit`)"
                size="small"
                icon="md-create"
                class="mr-10"
                :disabled="item.redirect_url ? true : false"
              />
            </Tooltip>

            <Tooltip
              v-if="item.status === -2 || item.status === 1"
              content="Publish"
              placement="top"
            >
              <Button @click="publish(item.id)" size="small" class="mr-10">
                <img alt="" class="icon" src="@/assets/product/publish.png" />
              </Button>
            </Tooltip>

            <Tooltip
              v-if="item.status === 2"
              content="Unpublish"
              placement="top"
            >
              <Button @click="unpublish(item.id)" size="small" class="mr-10">
                <img alt="" class="icon" src="@/assets/product/unpublish.png" />
              </Button>
            </Tooltip>

            <Tooltip content="Duplicate" placement="top">
              <Button
                class="mr-10"
                @click="copyProduct(item)"
                size="small"
                icon="ios-copy-outline"
              />
            </Tooltip>

            <Tooltip content="Delete" placement="top">
              <Button
                @click="removeProduct(item)"
                size="small"
                type="error"
                ghost
                icon="ios-trash"
              />
            </Tooltip>
          </div>
        </template>
      </Table>
      <div class="page">
        <Page
          transfer
          :page-size="limit"
          :page-size-opts="[4, 8, 12, 16]"
          :total="total"
          show-sizer
          show-elevator
          show-total
          @on-change="pageChange"
          @on-page-size-change="pageSizeChange"
        />
      </div>
    </div>

    <Modal v-model="toSortPop" title="Set the rank order of each product">
      <div class="info" style="color:#999;font-size:12px;padding-bottom:15px">
        For example, enter "1" if you want this product to show up first in the
        list.
      </div>
      <Input
        v-model="filter.search"
        prefix="ios-search"
        placeholder="Search by product name..."
        style="width: 100%;"
        @on-change="queryMyProductListSort"
      />
      <div class="list-table">
        <Table
          :loading="tloading"
          max-height="260"
          :columns="sortCol"
          :data="sortLists"
        >
          <template slot-scope="{ row, index }" slot="ranking">
            <Input
              type="number"
              :value="sortLists[index].sort"
              @on-change="changeSort(...arguments, index)"
            />
          </template>
        </Table>
      </div>
      <div slot="footer">
        <Button type="warning" ghost @click="toSortPop = false">Cancel</Button>
        <Button type="warning" @click="setSorts">Set Ranking</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import api from "@/api";
import { MatchMedia } from "vue-component-media-queries";

const {
  _queryMyCompany,
  _queryMyProductList,
  _removeProduct,
  _publishProduct,
  _unpublishProduct,
  _queryMyProductListSort,
  _setSorts,
  _createProduct,
  _createDraft,
  _queryProduct
} = api;

export default {
  name: "products",
  components: {
    MatchMedia
  },
  data() {
    return {
      screenWidth: window.innerWidth > 840,
      columns: [
        {
          title: "PRODUCT NAME",
          slot: "name"
        },
        {
          title: "FACTORY",
          slot: "factory"
        },
        {
          title: "TYPE",
          slot: "type",
          width: 150
        },
        {
          title: "STATUS",
          slot: "status",
          width: 200
        },
        {
          title: "Action",
          key: "action",
          width: 200,
          align: "center",
          slot: "action"
        }
      ],

      modType: "card",
      sortLists: [],
      sortDatas: [],
      toSortPop: false,
      tloading: false,

      sortCol: [
        {
          type: "index",
          width: 60,
          align: "center"
        },
        {
          title: "PRODUCT NAME",
          key: "marketing_name"
        },
        {
          title: "Ranking",
          slot: "ranking",
          width: 150,
          align: "center"
        }
      ],

      page: 1,
      limit: 12, // must in 5-20
      total: 0,
      list: [],
      loading: false,

      filter: {
        search: ""
      },

      hackInfomation: null
    };
  },
  created() {
    this.queryMyProductList();
    this.queryMyProductListSort();

    _queryMyCompany()
      .then(response => {
        const { data } = response;

        if (
          data.landing_image.url ===
          "https://connexima.s3-us-west-1.amazonaws.com/documents/5XgkbV5SipTUX8B9PR89cM.jpeg"
        ) {
          this.hackInfomation = "cover";
        }

        if (
          data.avatar.url ===
          "https://connexima.s3-us-west-1.amazonaws.com/documents/pDqTcsvD3oQAstALpYawe7.jpeg"
        ) {
          this.hackInfomation = "avatar";
        }
      })
      .catch(({ message }) => {
        this.$Message.error(message);
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    copyProduct(item) {
      this.$Modal.confirm({
        loading: true,
        title: "Confirm Duplicate Listing",
        content: "Are you sure you want to duplicate this product listing?",
        onOk: () => {
          _queryProduct({ id: item.id }).then(response => {
            const { data } = response;
            let form1 = {};
            let form2 = {};
            let form3 = {};
            let form4 = {};
            let form5 = {};
            let form6 = {};

            form1.marketing_name = data.marketing_name;
            form1.descriptive_name = data.descriptive_name;
            form1.other_common_name = data.other_common_name;
            form1.product_type = data.product_type;
            form1.factory_id = data.factory_id;
            form1.origin_country = data.origin_country;
            form1.manufacture_country = data.manufacture_country;
            form1.cas = data.cas;
            form1.sort = data.sort || 10;
            if (data.industry_usage_grade) {
              form1.industry_usage_grade = data.industry_usage_grade;
            }
            if (data.compendial_standard) {
              form1.compendial_standard = data.compendial_standard;
            }
            if (data.warehouses && data.warehouses.length > 0) {
              form1.warehouse = data.warehouses;
            }
            if (data.botanicals_additional_details) {
              form1.botanicals_additional_details =
                data.botanicals_additional_details;
            }

            // from2
            form2.description = data.property.description;
            if (data.property.appearance) {
              form2.appearance = data.property.appearance;
            }
            if (data.property.solubility) {
              form2.solubility = data.property.solubility;
            }

            if (data.property.composition_percent) {
              form2.composition_percent = data.property.composition_percent.map(
                item => {
                  item.composition = Number(item.composition);
                  return item;
                }
              );
            }

            if (data.property.particle_size) {
              form2.particle_size = data.property.particle_size.map(item => {
                item.yield = Number(item.yield);
                return item;
              });
            }

            if (data.property.storage_and_handling) {
              form2.storage_and_handling = data.property.storage_and_handling;
            }

            if (data.property.common_industries_served) {
              form2.common_industries_served =
                data.property.common_industries_served;
            }

            // form3
            if (data.specification.assay_claims) {
              form3.assay_claims = data.specification.assay_claims;
            }

            form3.chemical_formula = data.specification.chemical_formula;
            form3.molecular_weight = data.specification.molecular_weight;
            form3.processing_method = data.specification.processing_method;
            form3.starting_material = data.specification.starting_material;
            if (data.specification.solvents_used_in_production) {
              form3.solvents_used_in_production =
                data.specification.solvents_used_in_production;
            }

            // form4
            if (data.contaminant.microbial_contaminants_breakdown) {
              form4.microbial_contaminants_breakdown = data.contaminant.microbial_contaminants_breakdown.map(
                item => {
                  item.microbial_count = Number(item.microbial_count);
                  return item;
                }
              );
            }

            if (data.contaminant.heavy_metals_breakdown) {
              form4.heavy_metals_breakdown = data.contaminant.heavy_metals_breakdown.map(
                item => {
                  item.heavy_metal_count = Number(item.heavy_metal_count);
                  return item;
                }
              );
            }

            //form5
            if (data.documents) {
              form5.document = data.documents.map(item => item.id);
            }

            // form6
            if (data.video) {
              form6.video = data.video[0] && data.video[0].url;
            }

            if (data.images) {
              form6.images = data.images.map(img => img.url);
            }

            // this.form7.market_trend = data.market_trend;

            const cloneData = {
              ...form1,
              ...form2,
              ...form3,
              ...form4,
              ...form5,
              ...form6
            };

            if (item.status === -3) {
              _createDraft(cloneData)
                .then(() => {
                  this.$Modal.remove();
                  this.$Message.success("success");
                  this.queryMyProductList(); // refresh
                })
                .catch(({ message }) => {
                  this.$Modal.remove();
                  this.$Message.error(message);
                });
            } else {
              _createProduct(cloneData)
                .then(() => {
                  this.$Modal.remove();
                  this.$Message.success("success");
                  this.queryMyProductList(); // refresh
                })
                .catch(({ message }) => {
                  this.$Modal.remove();
                  this.$Message.error(message);
                });
            }
          });
        }
      });
    },

    unpublish(id) {
      _unpublishProduct({ id })
        .then(() => {
          this.$Message.success("success");
          this.queryMyProductList();
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        });
    },

    publish(id) {
      _publishProduct({ id })
        .then(() => {
          this.$Message.success("success");
          this.queryMyProductList();
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        });
    },

    handleSearch() {
      this.page = 1;
      this.queryMyProductList();
    },

    pageChange(page) {
      this.page = page;
      this.queryMyProductList();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.queryMyProductList();
    },

    removeProduct(item) {
      this.$Modal.confirm({
        loading: true,
        title: "Confirm Delete",
        content: "Are you sure to delete this item?",
        onOk: () => {
          _removeProduct({
            id: item.id
          })
            .then(() => {
              this.$Modal.remove();
              this.$Message.success("success");
              this.queryMyProductList(); // refresh
            })
            .catch(({ message }) => {
              this.$Modal.remove();
              this.$Message.error(message);
            });
        }
      });
    },

    queryMyProductList() {
      this.loading = true;

      _queryMyProductList({
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    queryMyProductListSort() {
      this.tloading = true;
      // this.loading = true;
      // this.modType = "";
      // this.toSort = true;
      var datas = {
        ...this.filter
      };

      delete datas.isTrusted;
      _queryMyProductListSort(datas)
        .then(response => {
          const { data } = response;
          this.sortLists = data;

          this.sortDatas = [];
          this.sortLists.forEach(item => {
            this.sortDatas.push({ id: item.id, sort: item.sort });
          });
          this.tloading = false;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.tloading = false;
        });
    },
    changeSort(event, index) {
      // this.sortDatas.push({ id: id, sort: event.target.value })
      this.sortDatas[index].sort = event.target.value;
    },
    setSorts() {
      this.toSortPop = false;
      this.loading = true;
      _setSorts({ product: this.sortDatas }).then(() => {
        this.$Message.success("ranking success");
        this.queryMyProductList(); // refresh
        this.filter.search = "";
        this.queryMyProductListSort();
      });
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  .filter {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    justify-content: space-between;
    overflow-y: auto;
    .total {
      color: #333;
      .tip {
      }
      .num {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .search-box {
      // flex: 1;
      flex: none;
      height: 40px;
      display: flex;
      margin: 0 20px;
      .input-box {
        position: relative;
        flex: 1;
        height: 100%;
        background: #fff;
        line-height: initial;
        padding-left: 20px;
        padding-right: 20px;
        border: 1px solid #dfdfdf;
        border-right: none;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        input {
          width: 100%;
          height: 100%;
          outline: none;
          background: transparent;
          border: none;
          color: #444;
          caret-color: #888;

          &::-webkit-input-placeholder {
            color: #bdcada;
          }
          &:-moz-placeholder {
            color: #bdcada;
          }
          &::-moz-placeholder {
            color: #bdcada;
          }
          &:-ms-input-placeholder {
            color: #bdcada;
          }
        }
      }
      .btn {
        cursor: pointer;
        background: #fff;
        color: @primary-color;
        border: 1px solid @primary-color;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        justify-self: center;
        line-height: initial;
        height: 100%;
      }
    }
    .type-wrap {
      flex: none;
      color: #ffca94;
      font-size: 20px;
      margin-right: 20px;
      cursor: pointer;
      /deep/ .ivu-icon-ios-list {
        position: relative;
        top: 4px;
        font-size: 32px;
        font-weight: bold;
      }
      .icon-sort {
        width: 20px;
        position: relative;
        top: 2px;
        margin: 0 10px;
      }
      .cur {
        color: @primary-color;
      }
    }
  }
  .empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cards {
    min-height: 300px;
    position: relative;
    .infomation {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      z-index: 1;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .info-box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: rgba(0, 0, 0, 0.6);
        padding: 30px;
        border-radius: 10px;
        width: 400px;
        .icon {
          font-size: 30px;
          font-weight: bold;
        }
        .text {
          text-align: center;
        }
      }
    }
    .action {
      border-top: 1px solid #ccc;
      padding: 10px;
      .icon {
        display: inline-block;
        width: 15px;
        vertical-align: middle;
      }
    }
    .item {
      margin-bottom: 20px;
      border: 1px solid #ccc;
      background: #fff;
      .img-box {
        width: 100%;
        height: 0px;
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
        border-bottom: 1px solid #ccc;
        .avatar {
          display: block;
          width: 100%;
        }
        .draft {
          background: #909090;
          position: absolute;
          right: 4px;
          top: 4px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          color: #fff;
          padding: 4px;
        }
      }

      .name {
        padding: 10px;
        height: 60px;
        border-bottom: 1px solid #ccc;
        color: #666;
        overflow: hidden;
        .product-name {
          font-size: 14px;
          color: #333;
          font-weight: bold;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .factory-name {
          font-size: 12px;
          color: #333;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      .user {
        padding: 10px;
        border-bottom: 1px solid #ccc;
        display: flex;
        align-items: center;
        .user-avatar {
          width: 20px;
          height: 20px;
          background: #ccc;
          border-radius: 50%;
          margin-right: 10px;
          overflow: hidden;
          .avatar {
            display: block;
            width: 100%;
          }
        }
        .user-name {
          font-size: 12px;
          color: #000;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .tags {
        padding: 10px;
        height: 50px;
        display: flex;
        align-items: center;
        overflow: hidden;
        border-bottom: 1px solid #ccc;
        .tag {
          color: #fff;
          border-radius: 3px;
          font-size: 12px;
          margin-right: 4px;
          height: 25px;
          display: flex;
          align-items: center;
          padding: 0 5px;
          font-weight: bold;
        }
      }
    }
    .page {
      margin: 10px 0;
      display: flex;
      justify-content: flex-end;
    }
  }

  .list {
    .list-head {
      display: flex;
      align-items: center;
      .img-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .avatar {
        width: 40px;
      }
      .draft {
        background: #909090;
        position: absolute;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #fff;
        padding: 4px;
      }
      .name {
        margin-left: 10px;
      }
    }
    .page {
      margin: 10px 0;
      display: flex;
      justify-content: flex-end;
    }

    .action {
      padding: 10px;
      .icon {
        display: inline-block;
        width: 15px;
        vertical-align: middle;
      }
    }
  }

  .status {
    padding: 10px;
    .key {
      color: #7f7f7f;
    }
    .value {
      color: #aaaaaa;
      &.success {
        color: #4caf50;
      }
      &.fail {
        color: #d9001b;
        text-decoration: underline;
        cursor: pointer;
        .tip {
          font-weight: bold;
          font-size: 16px;
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
